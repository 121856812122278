import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/client'

import { Form, Formik, FormikValues } from 'formik'
import ym from 'react-yandex-metrika'

import { routes } from '../../../routeConfig'
import { useSession } from '../../../hooks/auth/useSession'
import { Wrapper, Input } from '../../UI/Form'
import Button from '../../UI/Button/Button'
import SmallLoader from '../../UI/SmallLoader/SmallLoader'
import styles from './SignUpForm.module.css'

import { allowedUtmTags } from './constants'
import SignUpSchema from './validation'
import ymGoals from '../../../analytics/ymGoals'
import { formatUtmKey } from './formatUtmKey'
import {customHeaders} from '../../../apollo/constants';

const SIGN_UP = loader('../../../apollo/mutations/Register.graphql')

const SignUpForm = () => {
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const utmHeaders: Record<string, string> = {}
  searchParams.forEach((value, key) => {
    if (allowedUtmTags.includes(key)) {
      const formattedKey = formatUtmKey(key)
      utmHeaders[`X-${formattedKey}`] = value
    }
  })
  const [signUp] = useMutation(SIGN_UP, {
    context: {
      headers: {
        ...customHeaders,
        ...utmHeaders
      }
    }
  })
  const session = useSession()

  function formSubmit(values: FormikValues, { setSubmitting, setFieldError }: any): void {
    const { email, password } = values
    setSubmitting(true)
    signUp({
      variables: {
        email,
        password
      }
    })
      .then(({ data }) => {
        const { token, refreshToken }: SignUpResult = data.register
        session.login(token, refreshToken)
        ym('reachGoal', ymGoals.register.success)
        history.push(routes.survey)
      })
      .catch((error) => {
        if (error.message === 'email_unavailable') {
          setFieldError('email', 'Указанный email уже зарегистрирован')
          setSubmitting(false)
        } else {
          console.error(error.message)
          setSubmitting(false)
        }
      })
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={SignUpSchema}
      onSubmit={formSubmit}
      validateOnBlur={false}
    >
      {(props) => (
        <Wrapper>
          <Form>
            <h2 className={styles.title}>Регистрация</h2>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input name='email' placeholder='Адрес электронной почты' formik={props} withError/>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Input type='password' name='password' placeholder='Пароль' formik={props} withError/>
              </div>
            </div>
            <p className={styles.text}>
              Регистрируясь, вы подтверждаете что ознакомились с нашей <Link to={routes.privacy}>Политикой конфиденциальности</Link> и <Link to={routes.tos}>Пользовательским соглашением</Link> и принимаете их.
            </p>
            <Button
              className={styles.submit}
              type='submit'
              solid
              disabled={props.isSubmitting}
              onClick={() => ym('reachGoal', ymGoals.register.registerBtnClick)}
            >
              {props.isSubmitting ? <SmallLoader /> : 'Зарегистрироваться'}
            </Button>
            <div className={styles.underline}>
              <Link
                to={routes.signIn}
                className={styles['underline__link']}
              >
                Войти
              </Link>
            </div>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )
}

export default SignUpForm
