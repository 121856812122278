import React, { useEffect, } from 'react'
import Logo from '../Logo/Logo'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useApolloClient, gql } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { isAfterSurvey } from '../../apollo/cache'
import { useSession } from '../../hooks/auth/useSession'
import { routes } from '../../routeConfig'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { useLivechat } from '../../services/Livechat'

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3E3E3E;
  height: 40px;
`

const LogoLink = styled(Link)`
  display: block;
  padding: 5px 15px;
  & svg {
    max-width: 130px;
    max-height: 30px;
  }
  & sup {
    font-size: x-small;
    margin: 3px 0 0 5px;
  }
`

const DropdownListItem = styled.li<{disabled?: boolean}>`
  width: 100%;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: block;
  font-size: .875rem;
  color: #000000;
  &:hover {
    text-decoration: none;
    background-color: ${({ disabled }) => disabled ? null : '#DDDDDD'};
  }
  &:active {
    background-color: ${({ disabled }) => disabled ? null : 'darkgrey'};
  }
  & a {
    display: block;
    color: #000000;
    cursor: inherit;
    padding: 12px 15px;
    &:hover {
      text-decoration: none;
    }
  }
  & span {
    color: #666666;
    padding: 12px 15px;
  }
`

const DropdownList = styled.ul`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  right: 0;
  min-width: 220px;
  background-color: #FFFFFF;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  transition: right .3s ease-in;
  z-index: 150;
  &:hover {
    opacity: 1;
    visibility: visible;
  }
`

const Username = styled.span`
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: #FFFFFF;
  font-size: .875rem;
  &:after {
    content: '';
    border-width: 0.15em 0.15em 0 0;
    border-style: solid;
    height: 0.4rem;
    transform: rotate(135deg);
    width: 0.4rem;
    margin: -5px 0 0 10px;
  }
  &:hover + ${DropdownList} {
    opacity: 1;
    visibility: visible;
  }
`

const User = styled.div`
  height: 100%;
  &:hover {
    background-color: #FFFFFF;
  }
  &:hover > ${Username} {
    color: #000000;
  }
  &:hover > ${Username}:after {
    transform: rotate(-45deg);
    margin-top: 3px;
  }
`

const Tooltip = styled(ReactTooltip)`
  max-width: 320px;
  & p {
    line-height: 1.35;
  }
`


const AppHeader = () => {
  const history = useHistory()
  const location = useLocation()
  const isAfterSurveyResult = useReactiveVar(isAfterSurvey)
  const session = useSession()
  const client = useApolloClient()
  const livechat = useLivechat()

  const user = client.readFragment({
    id: 'User:{}',
    fragment: gql`
        fragment CurrentUser on User {
            id
            firstName
            lastName
            email
            emailValidated
            metadata {
              yesterdayWorkoutCompleted
              daysSinceSignup
              programmGroupId
              paying
            }
        }
    `
  })

  useEffect(() => {
    livechat({
      initState: 'minimize',
      initData: {
        name: `${user?.firstName} ${user?.lastName}`,
        email: `${user?.email}`
      },
      customVariables: {
        emailValidated: `${user?.emailValidated}`,
        yesterdayWorkoutCompleted: `${user?.metadata?.yesterdayWorkoutCompleted}`, 
        daysSinceSignup: `${user?.metadata?.daysSinceSignup}`,
        paying: `${user?.metadata?.paying}`,
        programmGroupId: `${user?.metadata?.programmGroupId}`
      }
    })
    return () => window.LiveChatWidget.call('destroy')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (isSubscriptionExpired() && history.action !== 'REPLACE') ||
      (history.action === 'REPLACE' && history.location.pathname === routes.planbuilder)
    ) {
      history.replace({
        pathname: routes.subscription,
        search: '?tab=status'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (isAfterSurveyResult) {
      const timeout = setTimeout(() => history.push(routes.planbuilder), 1000)
      return () => clearTimeout(timeout)
    }
  }, [isAfterSurveyResult, history])

  function isSubscriptionExpired(): boolean {
    return user?.paymentSubscription?.status === 'ENDED'
  }

  return (
    <Header data-modal='shift'>
      <LogoLink to={routes.calendar}>
        <Logo />
      </LogoLink>
      <User>
          <Username>
            {user
              ? ((user.firstName) ? `${user.firstName} ${user.lastName}` : `${user.email}`)
              : `'No User'`}
          </Username>
        <DropdownList>
          <DropdownListItem>
            <Link
              to={{
                pathname: routes.settings,
                search: '?tab=account',
                state: { background: location }
              }}
            >
              Настройки
            </Link>
          </DropdownListItem>
          <DropdownListItem disabled={isSubscriptionExpired()}>
            { !isSubscriptionExpired()
              ? (
                <Link
                  to={{
                    pathname: routes.planbuilder,
                    state: { background: location }
                  }}
                >
                  Конструктор плана
                </Link>
              )
              : (
                <>
                  <span data-tip data-for='planbuilder-prompt'>Конструктор плана</span>
                  <Tooltip id='planbuilder-prompt' place='left' effect='solid'>
                    <p>Доступно при активной подписке</p>
                  </Tooltip>
                </>
              )
            }
          </DropdownListItem>
          <DropdownListItem>
            <Link
              to={{
                pathname: routes.subscription,
                search: '?tab=status',
                state: { background: location }
              }}
            >
              Подписка
            </Link>
          </DropdownListItem>
          <DropdownListItem>
            <a
              href="https://trisystems.atlassian.net/servicedesk/customer/portals"
              target='_blank'
              rel='noreferrer'
            >
              Центр поддержки
            </a>
          </DropdownListItem>
          <DropdownListItem>
            <Link
              to={routes.signIn}
              onClick={() => session.logout()}
            >
              Выйти
            </Link>
          </DropdownListItem>
        </DropdownList>
      </User>
    </Header>
  )
}

export default React.memo(AppHeader)
